.login-container {
    display: flex;
    height: 100vh;
    width: 100%;
    background-color: #FFFFFF;
}


.login-left-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #FFFFFF;
    gap: 64px;
}

.login-logo {
    width: 202px;
    height: 64px;
    margin-left: -30px;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.login-options {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.divider {
    display: flex;
    align-items: center;
    text-align: center;
    color: #999;
    font-size: 0.9rem;
}

.divider:before,
.divider:after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #D0D0D0;
}

.divider span {
    padding: 0 0.8rem;
}

.login-promo-section {
    color: white;
    display: flex;
    width: 50%;
    min-width: 50%;

    @media only screen and (max-width: 1220px) {
        display: none !important;
    }
}

.login-image{
    width: 100%;
    height: 100%;
    min-width: 708px;
    object-fit: cover;
}